const testi = {
    titoloServizio1: 'Ristrutturazioni Complete',
    titoloServizio2: 'Installazione di Riscaldamento a Pavimento',
    titoloServizio3: 'Isolamento e Riqualificazione Energetica',
    titoloServizio4: 'Costruzione di Nuove Abitazioni',
    titoloServizio5: 'Realizzazione di Spazi Esterni Personalizzati',
    titoloServizio6: `Innovazioni nei Sistemi di Ricambio d'Aria`,
    descrizioneServizio1: `Il servizio di ristrutturazioni complete offerto dalla nostra azienda
    edile è la soluzione ideale per trasformare la tua casa o il tuo 
    locale commerciale. Con una vasta esperienza nel settore,
    ci occupiamo di tutti gli aspetti del progetto, 
    dalla progettazione all'implementazione. 
    Effettuiamo la demolizione, il rifacimento di pareti,
     pavimenti, impianti e finiture per creare un ambiente
      completamente nuovo che soddisfi le tue esigenze e il tuo stile.`,
    descrizioneServizio2:`Il riscaldamento a pavimento è una soluzione moderna ed 
    efficiente per il comfort termico delle abitazioni. 
    La nostra azienda edile è specializzata nell'installazione 
    di sistemi di riscaldamento a pavimento,
     che offrono un calore uniforme e una migliore 
     gestione dell'energia. I nostri esperti installeranno un
     sistema su misura per la tua casa, migliorando l'efficienza
      energetica e creando un ambiente confortevole per tutto l'anno. 
      Con il riscaldamento a pavimento, puoi dire addio ai radiatori
       tradizionali e godere di un comfort termico ineguagliabile.`,
    descrizioneServizio3:  `La riduzione dei consumi energetici e il miglioramento 
       dell'efficienza sono diventati sempre più importanti. 
       Offriamo servizi di isolamento termico ed energetico 
       per le tue abitazioni. 
       Questi interventi possono non solo aiutarti a 
       risparmiare sui costi energetici a lungo termine, 
       ma anche a contribuire alla sostenibilità ambientale.`,
    descrizioneServizio4: ` Se stai cercando di costruire la casa dei tuoi sogni, 
       siamo qui per aiutarti a trasformare il tuo progetto in realtà.
        La nostra azienda edile dispone di architetti e ingegneri
         esperti che collaboreranno con te per progettare e 
         costruire una nuova abitazione su misura.
          Utilizziamo materiali di alta qualità e tecniche avanzate
           per garantire una costruzione solida e duratura.`,
    descrizioneServizio5: `  I nostri servizi edili non si limitano all'interno degli edifici.
    Creiamo spazi esterni personalizzati, come giardini, 
    terrazzi e piscine. La nostra squadra di esperti paesaggisti e
     progettisti può aiutarti a trasformare il tuo spazio esterno
      in un'oasi di tranquillità o in un luogo ideale per 
      intrattenere gli amici e la famiglia.

      Scegliendo la nostra azienda edile, 
      avrai accesso a una vasta gamma di servizi dedicati a migliorare 
      la tua abitazione o il tuo spazio commerciale, garantendo 
      qualità e professionalità in ogni progetto.`,
    descrizioneServizio6: `Il nostro innovativo sistema di ricambio d'aria per abitazioni è progettato
    per migliorare la qualità dell'aria all'interno della tua casa. 
    Grazie a tecnologie all'avanguardia, garantiamo un ambiente fresco, pulito
     e salutare per te e la tua famiglia. Questo sistema intelligente regola automaticamente
      il flusso d'aria in base alla qualità dell'aria interna e alle preferenze personali,
       garantendo un comfort ottimale.`,
};

export default testi;