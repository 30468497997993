import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import '../Stile/Servizi.css'; // Importa il tuo file CSS
import testi from './Testi';
import imageurl from '../Immagini/Sfondo1.png';
import costruzione from '../Immagini/costruzione.jpg';
import esterni from '../Immagini/esterni.jpg';
import pavimento from '../Immagini/pavimentoR.jpg';
import energetica from '../Immagini/energetica.jpg'
import ricambi from '../Immagini/ricambi aria.png'

import Card from './Card';
import CardInversa from './CardInversa';

const Servizi2 = () => {
  const [scrolling, setScrolling] = useState(false);


  const slideVariants = {
    hidden: { opacity: 0, x: '-100%' },
    visible: { opacity: 1, x: 0 },
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolling(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <div>
       <div className='titoloservizio'>
        <h2 className="testos"> SERVIZI PERSONALIZZATI <br/>PER SODDISFARE LE TUE ESIGENZE </h2>
      </div>
       <motion.div
        initial="hidden"
        animate={scrolling ? 'visible' : 'hidden'}
        variants={slideVariants}
        transition={{ duration: 1.5 }}
      >
          <Card imageUrl={imageurl} titolo ={testi.titoloServizio1} descrizione = {testi.descrizioneServizio1} />
        </motion.div>
        <motion.div
        initial="hidden"
        animate={scrolling ? 'visible' : 'hidden'}
        variants={slideVariants}
        transition={{ duration: 1.5, delay: 2 }}
      >
        <CardInversa imageUrl={pavimento} titolo ={testi.titoloServizio2} descrizione = {testi.descrizioneServizio2} />
        </motion.div>
        <motion.div
        initial="hidden"
        animate={scrolling ? 'visible' : 'hidden'}
        variants={slideVariants}
        transition={{ duration: 1.5, delay: 4 }}
      >
        <Card imageUrl={energetica} titolo ={testi.titoloServizio3} descrizione = {testi.descrizioneServizio3} />
        </motion.div>
        <motion.div
        initial="hidden"
        animate={scrolling ? 'visible' : 'hidden'}
        variants={slideVariants}
        transition={{ duration: 1.5, delay: 6 }}
      >
        <CardInversa imageUrl={costruzione} titolo ={testi.titoloServizio4} descrizione = {testi.descrizioneServizio4} />
        </motion.div>
        <motion.div
        initial="hidden"
        animate={scrolling ? 'visible' : 'hidden'}
        variants={slideVariants}
        transition={{ duration: 1.5, delay: 8 }}
      >
        <Card imageUrl={esterni} titolo ={testi.titoloServizio5} descrizione = {testi.descrizioneServizio5} />
        </motion.div>
        <motion.div
        initial="hidden"
        animate={scrolling ? 'visible' : 'hidden'}
        variants={slideVariants}
        transition={{ duration: 1.5, delay: 10 }}
      >
        <CardInversa imageUrl={ricambi} titolo ={testi.titoloServizio6} descrizione = {testi.descrizioneServizio6} />
        </motion.div>
    </div>
  );
};

export default Servizi2;
