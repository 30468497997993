import React from 'react';
import '../Stile/Galleria.css'; // Assicurati di creare un file CSS per stili aggiuntivi
import Image from './Image';
import cucina from '../Immagini/cucina.jpg';
import soggiorno from '../Immagini/soggiorno.jpg';
import soggiorno1 from '../Immagini/soggiorno1.jpg';
import ufficio from '../Immagini/ufficio.jpg';
import piscina from '../Immagini/piscina.jpg';
import terrazzo from '../Immagini/terrazzo.jpg';
import letto from '../Immagini/letto.jpg';
import bagno from '../Immagini/bagno.jpg';
import salap from '../Immagini/sala pranzo.jpg';
// Galleria.jsx


const images = [
  { url: cucina, text: 'Cucina' },
  { url: soggiorno, text: 'Soggiorno' },
  { url: soggiorno1, text: 'Soggiorno Moderno' },
  { url: ufficio, text: 'Ufficio' },
  { url: piscina, text: 'Piscina' },
  { url: terrazzo, text: 'Terrazzo' },
  { url: letto, text: 'Camera da Letto' },
  { url: bagno, text: 'Bagno' },
  { url: salap, text: 'Sala Pranzo' },
];

const Cell = ({ imageUrl, imageText }) => (
  <div className="cell" data-effect="fade-in">
    <Image src={imageUrl} className="cell-img" data-text={imageText} />
    <figcaption>
      <h2>{imageText}</h2>
    </figcaption>
  </div>
);

const Galleria = () => {
  return (
    <div className="grid">
      <div className='titologalleria'>
        <h2 className="testog"> Galleria </h2>
      </div>
      {[...Array(3)].map((row, rowIndex) => (
        <div key={rowIndex} className="row">
          {[...Array(3)].map((col, colIndex) => (
            <Cell
              key={colIndex}
              imageUrl={images[rowIndex * 3 + colIndex].url}
              imageText={images[rowIndex * 3 + colIndex].text}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default Galleria;

