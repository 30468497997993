// Navbar.jsx
import React, { useState, useEffect } from 'react';
import '../Stile/Navbar.css';
import Image from './Image'
import logo from '../Immagini/logo ok2.png'



const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const [lastScrollY, setLastScrollY] = useState(0);
  const [show, setShow] = useState(true);
  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
      setShow(false); 
    } else { // if scroll up show the navbar
      setShow(true);  
    }

    // remember current page location to use in the next move
    setLastScrollY(window.scrollY); 
  };

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar);

    // cleanup function
    return () => {
       window.removeEventListener('scroll', controlNavbar);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollY]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    
    <div className={`navbar ${show ? '' : 'show'}`}>
      <div className="logo">
        <Image src={logo} className="logo-image" />
      </div>
      <div className={`nav-links ${menuOpen ? 'show' : ''}`}>
        <a href="#home" className="nav-link">
          HOME
        </a>
        <a href="#servizi" className="nav-link">
          SERVIZI
        </a>
        <a href="#galleria" className="nav-link">
          GALLERIA
        </a>
        <a href="#contatti" className="nav-link">
          CONTATTI
        </a>
      </div>
      <div className={`hamburger`} onClick={toggleMenu}>
        <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
        <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
        <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
        <div className={`close ${menuOpen ? 'open' : ''}`}> X </div>
      </div>
      <div className={`nav-links-mobile ${menuOpen ? 'show' : ''}`}>
        <a href="#home" className="nav-link">
          HOME
        </a>
        <a href="#servizi" className="nav-link">
          SERVIZI
        </a>
        <a href="#galleria" className="nav-link">
          GALLERIA
        </a>
        <a href="#contatti" className="nav-link">
          CONTATTI
        </a>
      </div>
    </div>
  );
};

export default Navbar;
