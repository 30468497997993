import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import '../Stile/Home.css';
import wall from '../Immagini/wall 2.png';

const Home = () => {
  const [scrollY, setScrollY] = useState(0);
  const controls = useAnimation();

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    controls.start({ opacity: 1 - scrollY / 2000 }); // Modifica il valore 500 secondo le tue esigenze
  }, [scrollY, controls]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="home-section">
      <motion.img
        src={wall}
        alt="Background"
        className="background-image"
        style={{ opacity: 1 }}
        animate={controls}
      />
      <div className="overlay testo">
       
      </div>
    </div>
  );
};

export default Home;
