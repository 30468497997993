import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./Components/Navbar";
import Home2 from "./Components/Home"

import Footer from "./Components/Footer";
import Servizi from "./Components/Servizi";
import Galleria from "./Components/Galleria";
import Contatti from "./Components/Contatti";

function App() {
  return (
    <div className="App">
     <Navbar/>
     <div id="home"><Home2/></div>
     <div id="servizi"><Servizi/></div>
     <div id="galleria"><Galleria/></div>
     <div id="contatti"><Contatti/></div>
     <Footer/>
    </div>
  );
}

export default App;
