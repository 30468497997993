import React from 'react';
import '../Stile/Contatti.css';
import mappa from '../Immagini/mappa1.png';
import Image from './Image';

const Contatti = () => {
  return (
    <div className="contatti-container">


      <div className="contatti-info">
        <h2>Contatti</h2>
        <p>
          Hai domande o hai bisogno di assistenza?
          <br />
           Contattaci tramite telefono o email.
        </p>
        <ul>
          <li>
            <span>Telefono:</span><br/>+39 3806851603
          </li>
          
          <li>
            <span>E-Mail:</span> <br />vincenzogeomerola@gmail.com
          </li>
          <li>
            <span>Indirizzo:</span> <br />Corso Amedeo di Savoia, 307, 80136 Napoli NA
          </li>
        </ul>
      </div>

      <div className="map">
        {/* Immagine con link al click */}
        <a href="https://maps.app.goo.gl/Z3t3vajMLT5yLyuw5" target="_blank" rel="noopener noreferrer">
          <Image src={mappa} alt="Mappa" className="map-image" />
        </a>
      </div>

    </div>
  );
};

export default Contatti;
