// CardComponent.jsx
import React from 'react';
import '../Stile/Servizi.css'; // Importa il tuo file CSS
import Image from "./Image"

const CardInversa = ({ imageUrl,titolo,descrizione }) => {
    
  return (
    <div className="mio-componente inversione">
    <div className="card">
    <div className="colonna immagine">
        <Image src={imageUrl}/>
      </div>     
      <div className="colonna testo">
        <h2>{titolo}</h2>
        <p>{descrizione}</p>
      </div>
    </div>
  </div>
  );
};

export default CardInversa;
