import React from 'react';
import '../Stile/Footer.css';
function Footer() {
  return (
<footer className="footer" >
  <div className="container">
    <p className="copyright text-center text-black testofooter">
      Copyright &copy; 2023 <a href="#home">Geometra Vincenzo Merola</a>. Tutti i diritti riservati.
    </p>
  </div>
</footer>
  );
}

export default Footer;
